import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EllaauthService } from '../services/ellaauth.service';
import {Md5} from 'ts-md5/dist/md5';
import { TranslateConfigService } from '../services/translate-config.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-edit-fulfill-modal',
  templateUrl: './edit-fulfill-modal.page.html',
  styleUrls: ['./edit-fulfill-modal.page.scss'],
})

export class EditFulfillModalPage implements OnInit {
  current_order_uuid: string;

  constructor( 
    public modalController: ModalController,
    private router: Router,
    private ellaauthService: EllaauthService,
    private route: ActivatedRoute,
    private translateConfigService: TranslateConfigService) {
      this.translateConfigService.getDefaultLanguage();
    }

  ngOnInit() {
      
      console.log( " current_order_uuid  = "+this.current_order_uuid);
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  
update_table_orders(order_uuid,status, table_number, table_type,clear_table){
  console.log( " order_uuid  = "+this.current_order_uuid);

  let store_info = localStorage.getItem("store_info");
  let master_restaurant_uuid = JSON.parse(store_info);
  
  
  master_restaurant_uuid = master_restaurant_uuid.master_restaurant_uuid;


  //md5($order_info_uuid."__".$order_status);
  let hash = Md5.hashStr(this.current_order_uuid+"__"+status);
  let payload = {"clear_table":clear_table, "order_info_uuid":this.current_order_uuid, "order_status":status, "order_hash":hash, "table_number":table_number, "table_type":table_type, "master_restaurant_uuid": master_restaurant_uuid};
  console.log("  **** ");
  console.log(payload);
 
  this.ellaauthService
      .apiReq('post', 'orderinfo/updateorderstatusviakitchen', payload)
      .subscribe((response) => {
        this.dismiss();

        if (response.status === false) {         
          this.ellaauthService.onError(response.message);
        } else {
          // this.utilityService.onSuccess(response.message);
          let data = response.aaData ;
          console.log("  RES  updateorderstatusviakitchen: ");
          console.log(data)
          
          
          //this.router.navigate(['/dashboard'])

          this.router.navigateByUrl('/dashboard', {skipLocationChange: true}).then(() => {
            this.router.navigate(["/dashboard"]);
          });
        }
        
      });
    
}

}
function Input() {
  throw new Error('Function not implemented.');
}

